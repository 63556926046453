// @import 'fonts/googlesans.css';

body {
  min-height: 100%;
  position: relative;
}

#topbar {
  display: flex;
  height: 69px;
  width: 100%;
  align-items: flex-end;
  position: fixed;
  background-color: white;
  margin-top: 0;
}

#searchbarimage {
  height: 30px;
  padding: 0px 28px 8px 30px;
  cursor: pointer;
}

#searchbarmic {
  flex: 1 0 auto;
  display: flex;
  cursor: pointer;
  align-items: center;
  border: 0;
  background: transparent;
  outline: none;
  padding: 0 12px;
  width: 44px;
  line-height: 44px;
  vertical-align: middle;
}

#searchbarmic img {
  height: 13px;
  width: 24px;
  //   visibility: hidden;
}

#searchbarclose {
  flex: 1 0 auto;
  cursor: pointer;
  border: 0;
  background: transparent;
  outline: none;
  padding: 0 8px;
  padding-right: 4px;
  line-height: 44px;
  margin-right: 6px;
  color: #70757a;
}

#searchbarclose svg {
  display: block;
  height: 24px;
  width: 24px;
  fill: currentColor;
}

.sideline-search {
  border-left: 1px solid #dfe1e5;
  height: 65%;
  margin-top: 6px;
}

#searchbarbutton {
  flex: 0 0 auto;
  padding-right: 13px;
  height: 44px;
  width: 44px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

#searchbarbutton svg {
  height: 25px;
  width: 35px;
  position: relative;
  left: -3px;
  top: 3px;
}

#searchbarbutton svg path {
  fill: #4285f4;
}

#searchbar {
  background: #fff;
  display: flex;
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  height: 39px;
  width: 690px;
  border-radius: 24px;
  z-index: 3;
  height: 44px;
  position: relative;
  margin-left: 12px;
  /*width: 625px;
    height: 45px;
    border-radius: 100px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    position: relative;
    bottom: 5px;
    overflow: hidden;
    display: flex;
    z-index: 100;*/
}

#searchbar:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

#searchbar > input {
  /*height: 45px;
    border-style: none;
    font-size: 16px;
    line-height: 45px;
    padding-left: 20px;
    flex: 1;*/

  height: 39px !important;
  line-height: 39px;
  margin-top: -42px;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  display: flex;
  flex: 100%;
  font-size: 16px;
}

#searchbar > input:focus {
  outline: none;
}

#searchbar button {
  border: none;
  cursor: pointer;
}

#searchbar button:focus {
  outline: none;
}

#boxesicon {
  width: 25px;
  height: 25px;
  //   background-image: url('images/boxes.png');
  opacity: 0.6;
  background-size: 26px;
  position: absolute;
  right: 130px;
  bottom: 14px;
}

#optionsmenuactive::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #4285f4;
  left: 0px;
  bottom: 0px;
}

#optionsmenu2 {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
  margin-left: 56px;
}

#optionsmenu2 li {
  padding: 0px 0px 15px 0px;
  font-size: 14px;
  font-family: Google Sans, arial, sans-serif;
}

#optionsmenu2 li a {
  color: #5f6368;
  text-decoration: none;
}

#optionsmenu2 li a:hover {
  color: #000000;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid #dadce0;
  background-color: #f8f9fa;
  border-radius: 2px;
  margin: 0 auto;
  margin-left: -13px;
  text-decoration: none;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2);
}

#searchresultsarea {
  margin-left: 180px;
  font-family: 'Arial';
}

.pagebar {
  width: 652px;
}

#searchresultsnumber {
  color: #70757a;
  font-size: 14px;
  font-family: Google Sans, arial, sans-serif;
}

.searchresult {
  /*margin-left: 8px;*/
  margin-top: 30px;
}

.searchresult h2 {
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
  color: #1a0dab;
  margin: 0 auto;
  padding-top: 4px;
  padding-bottom: 4px;
}

.searchresult a {
  font-size: 14px;
  line-height: 1.3;
  color: #5f6368;
  margin-bottom: 0px;
  text-decoration: none;
}

.searchresult h2:hover {
  text-decoration: underline;
}

.rating {
  color: #70757a;
}

.stars {
  //   background-image: url('images/star.png');
  background-repeat: repeat-x;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 63px;
  background-size: 13px 12px;
  height: 13px;
}

.button-save {
  font-size: 10px;
  line-height: 14px;
  color: #5f6368;
  margin-top: -3px;
  margin-left: 3px;
  padding: 0px;
  border-width: 0px;
  background-color: white;
}

#bellicon {
  width: 24px;
  height: 24px;
  /*background-color: rgb(105, 105, 105);
    background-image: url('images/bell.png');*/
  background-size: 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 30px;
  position: absolute;
  right: 180px;
  bottom: 6px;
  color: #70757a;
}

#bellicon svg {
  fill: currentColor;
}

#profilebutton {
  cursor: pointer;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  padding: 9px 15px;
  min-width: 80px;
  position: absolute;
  margin-left: 8px;
  outline: 0;
  background: #1a73e8;
  border: 1px solid transparent;
  font-family: Google Sans, Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 16px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
  right: 40px;
}

#optionsbar {
  width: 100%;
  height: 58px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: flex-end;
  font-family: 'Arial';
  font-size: 13px;
  color: rgb(112, 112, 112);
  padding-top: 74px;
}

#optionsmenu1 {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
  margin-left: 180px;
}

#optionsmenu1 li {
  padding: 0px 0px 15px 0px;
  font-size: 14px;
  font-family: Google Sans, arial, sans-serif;
  display: flex;
  margin-right: 24px;
}

#optionsmenuactive {
  color: #1a73e8;
  position: relative;
  z-index: -1;
}

.svg-icon {
  height: 16px;
  width: 16px;
  margin-right: 5px;
  vertical-align: text-bottom;
}
.svg-icon svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: currentColor;
}

.searchresult p {
  width: 625px;
  font-size: 14px;
  line-height: 1.58;
  margin-top: 0px;
  color: rgb(82, 82, 82);
}

.relatedsearches h3 {
  font-weight: normal;
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 10px;
  line-height: 28px;
  font-family: Google Sans, arial, sans-serif !important;
  color: #202124;
}

.relatedlists ul {
  list-style: none;
  color: rgb(29, 1, 189);
  padding-left: 0px;
  font-size: 14px;
  margin-bottom: 30px;
}

.relatedlists {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
}

.relatedlists ul li a {
  align-items: center;
  background-color: #f1f3f4;
  border-radius: 100px;
  box-sizing: border-box;
  display: flex;
  max-height: none;
  min-height: 48px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 4px;
  margin-top: 4px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.relatedlists ul li a {
  text-decoration: none;
}
.relatedlists ul li a:hover {
  text-decoration: underline;
}

.pagebar ul li a {
  color: #4285f4;
  text-decoration: none;
}
.pagebar ul li a:hover {
  color: #4285f4;
  text-decoration: underline;
}

.span-icon-search {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(0,0,0,.54)' d='M20.49 19l-5.73-5.73C15.53 12.2 16 10.91 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.41 0 2.7-.47 3.77-1.24L19 20.49 20.49 19zM5 9.5C5 7.01 7.01 5 9.5 5S14 7.01 14 9.5 11.99 14 9.5 14 5 11.99 5 9.5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  height: 20px;
  padding: 10px;
  width: 20px;
  border-radius: 4px;
  font-size: 14px;
}

.span-text-search {
  margin-left: 16px;
  color: #202124;
  flex: 1;
  font-size: 16px;
  max-width: 227px;
  overflow-wrap: break-word;
  overflow: hidden;
  margin: 0;
}

.pagelist {
  list-style: none;
  color: rgb(29, 135, 255);
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-bottom: 30px;
  margin-left: 100px;
}

.pagelistprevious::before {
  content: '<';
  display: block;
  position: absolute;
  right: 12px;
  top: -35px;
  color: rgb(29, 135, 255);
  font-size: 20px;
  transform: scale(0.7, 1.2);
}

.pagelistprevious::after {
  content: '';
  display: block;
  position: absolute;
  right: -40px;
  top: -35px;
  width: 40px;
  height: 40px;
  //   background-image: url('/assets/google/g.png');
  background-repeat: no-repeat;
  background-size: 28px;
}

.pagelistfirst {
  margin-left: 20px;
  font-size: 13px;
}

.pagelistnumber::before,
.pagelistfirst::before {
  content: '';
  display: block;
  position: absolute;
  left: -2px;
  top: -21px;
  border-radius: 50px;
  width: 10px;
  height: 10px;
  background-color: white;
  z-index: 3;
}

.pagelistnumber::after {
  content: '';
  display: block;
  position: absolute;
  left: -6px;
  top: -25px;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  background-color: rgb(255, 196, 0);
  z-index: 2;
}

.pagelistfirst::after {
  content: '';
  display: block;
  position: absolute;
  left: -6px;
  top: -25px;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  background-color: red;
  z-index: 2;
}

.pagelist li {
  /*margin-right: 15px;
    position: relative;*/
  height: 57px;
  text-align: center;
}

.pagelistnext {
  margin-left: 40px;
  font-size: 13px;
}

.pagelistnext::before {
  content: '>';
  display: block;
  position: absolute;
  left: 5px;
  top: -35px;
  color: rgb(29, 135, 255);
  font-size: 20px;
  transform: scale(0.7, 1.2);
}

.pagelistnext::after {
  content: '';
  display: block;
  position: absolute;
  left: -55px;
  top: -37px;
  width: 50px;
  height: 50px;
  //   background-image: url('images/gle.png');
  background-repeat: no-repeat;
  background-size: 48px;
}

#footer {
  background-color: rgb(238, 238, 238);
  position: relative;
  left: 0px;
  bottom: 0px;
  width: 100%;
  font-size: 14px;
  line-height: 40px;
  font-family: 'arial';
  color: rgb(85, 85, 85);
  padding-top: 12px;
  padding-bottom: 12px;
}

#footermenu {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 0;
  margin-left: 180px;
}

#footermenu a {
  color: #70757a;
  text-decoration: none;
}
#footermenu a:hover {
  color: #3c4043;
  text-decoration: none;
}

#footermenu li {
  padding-right: 30px;
}

#footerlocation {
  display: block;
  direction: row;
  margin-left: 180px;
  position: relative;
}

.footer-sideline {
  display: inline-block;
  margin-left: 13px;
  padding-left: 16px;
  border-left: 1px solid #dadce0;
  line-height: 15px;
  color: #70757a;
  font-size: 14px;
  height: 25px;
  vertical-align: middle;
}
.footer-sideline a {
  color: #70757a;
  text-decoration: none;
}
.footer-sideline a:hover {
  color: #3c4043;
  text-decoration: none;
}

.footer-circle {
  border-radius: 100%;
  display: inline-block;
  height: 10px;
  margin: 6px 4px 9px 0;
  vertical-align: middle;
  width: 10px;
  background: #70757a;
}

.footer-top {
  border-bottom: 1px solid #dadce0;
}

#footerlocation p:nth-of-type(1) {
  font-weight: bold;
}

@media screen and (max-width: 1068px) {
  #bellicon,
  #profilebutton {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  body {
    background: #f1f2f4;
  }

  #topbar {
    display: block;
    margin-top: 0;
    position: relative;
  }

  .logo {
    height: 40px;
    text-align: center;
    padding: 20px;
  }

  #searchbar {
    width: 95%;
  }

  #optionsbar {
    padding-top: 50px;
  }
  #searchbarclose,
  #searchbarmic,
  #optionsmenu2,
  #searchresultsnumber,
  .sideline-search,
  .svg-icon,
  .pagebar,
  .relatedright,
  .footer-sideline,
  .mobile {
    display: none;
  }
  #header {
    background: #ffffff;
  }
  #optionsmenuactive {
    z-index: 9;
  }
  #optionsmenu1 {
    margin-left: 6%;
    width: 94%;
  }

  #searchresultsarea {
    margin: 0 auto;
  }

  .searchresult,
  .relatedsearches {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 2%;
    padding-bottom: 2%;
    background: #ffffff;
    margin-top: 12px;
  }

  .searchresult p {
    width: 100%;
  }

  .relatedlists {
    width: 100%;
  }

  .relatedlists ul li a {
    background: #ffffff;
    border-bottom: 1px solid #f1f3f4;
    border-radius: 0;
  }

  #footerlocation,
  #footermenu {
    margin: 0 auto;
    text-align: center;
  }

  #footermenu li {
    padding-right: 10px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin-left: 6%;
    padding-top: 10px;
  }

  .relatedleft {
    width: 100%;
  }
}
