.contacts {
  background: white;
  padding-top: 32px;
  .calculate__block-text h1 {
    color: #020202 !important;
    span {
      color: #219d8b !important;
    }
  }
  .calculate__block-text h3 {
    color: #020202 !important;
  }
  &__map-mobile {
    display: none;
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 124.18%;
    display: flex;
    align-items: center;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    margin-bottom: 32px;
  }
  &__block-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 13px;
    border: 1px solid rgba(18, 18, 18, 0.05);
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    border-radius: 18px;
  }
  &__map {
    img {
      width: 100%;
    }
  }
}

.contact:first-child {
  padding-left: 32px;
  border-left: none;
}
.contact:last-child {
  padding-right: 32px;
}

.contact {
  margin: 32px 0px;
  padding-left: 16px;
  padding-right: 16px;
  border-left: 1px solid rgba(18, 18, 18, 0.05);

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 124.18%;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    margin-bottom: 12px;
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  }
  &__text-helper {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #219d8b;
    margin-bottom: 12px;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  }
}

@media (max-width: 992px) {
  .contacts {
    &__calc {
      // margin-left: 15px;
      // margin-right: 30px;
    }
    width: calc(100% - 32px);
    padding-left: 15px;
    padding-right: 15px;
    &__block-cards {
      grid-template-columns: repeat(1, 1fr) !important;
    }
    &__map {
      display: none;
    }
    &__map-mobile {
      display: block;
      img {
        width: 100%;
      }
    }
  }
}
