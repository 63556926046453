.showcase {
  border-top: 1px solid #eef1f3;
  background: white;
  padding-top: 32px;
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 124.18%;
    display: flex;
    align-items: center;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    padding-bottom: 16px;
  }
  &__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(2, 2, 2, 0.75);
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  }
  &__showcases {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 24px;
    column-gap: 24px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.card {
  background: #ffffff;
  border: 1px solid rgba(18, 18, 18, 0.05);
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  border-radius: 18px;
  padding: 24px;
  &__logo {
    img {
      height: 45px;
    }
  }
  &__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #020202;
    padding-top: 16px;
    padding-bottom: 32px;
    height: 63px;
  }
  &__sum {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 124.18%;
    padding-bottom: 24px;
  }
  &__percent {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 124.18%;
    padding-bottom: 24px;
    span {
      color: rgba(2, 2, 2, 0.75);
    }
  }
  &__date {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 124.18%;

    span {
      color: rgba(2, 2, 2, 0.75);
    }
  }
  &__button {
    padding-top: 36px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .showcase {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
    &__showcases {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
