@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
.container {
  width: 100%;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__row {
    flex: 1;
    max-width: 1280px;
    width: 100%;
  }
}

a {
  text-decoration: none;
  color: black;
}

.tg-icon {
  height: 45px;
  width: 45px;
  position: fixed;
  right: 25px;
  bottom: 35px;
  cursor: pointer;
  transition: 0.5s;
  z-index: 999;
}
.tg-icon:hover {
  transform: rotate(180deg);
  height: 50px;
  width: 50px;
}

.slick-next {
  display: none !important;
}

@media (max-width: 992px) {
  .tg-icon {
    bottom: 70px;
    right: 15px;
  }
}
