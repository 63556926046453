.home {
  width: 100%;
  &__about {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .container {
      background: #ffffff;
      box-shadow: 0px 18px 52.8537px rgb(215 228 249 / 25%);
      border-radius: 18px;
    }
    &__title {
      padding-bottom: 58px;
    }
    &__cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      z-index: 1;
    }
    &__button {
      margin-top: 48px;
      margin-bottom: 64px;
      z-index: 1;
    }
    &__img {
      position: absolute;
      bottom: 30px;
      left: 46%;
      z-index: 0;
    }
  }
  &__questions {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &__img-1 {
      position: absolute;
      left: 10%;
      top: 20%;
    }
    &__img-2 {
      position: absolute;
      right: 10%;
      bottom: 20%;
    }
    &__img-3 {
      position: absolute;
      left: -20%;
      top: 30%;
      z-index: 0;
    }
    &__button {
      margin-top: 48px;
      margin-bottom: 64px;
      z-index: 1;
    }
    &__title {
      padding-bottom: 58px;
    }
  }
  .articles {
    padding-bottom: 64px;
    &__title {
      display: flex;
      justify-content: center;
      padding-bottom: 58px;
    }
    &__cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 20px;
      column-gap: 20px;
    }
  }
}
@media (max-width: 996px) {
  .home__about {
    display: none;
  }
  .home {
    .accordion__title {
      padding-top: 7px;
      padding-bottom: 7px;
    }
    &__questions {
      &__button {
        margin-top: 24px;
        margin-bottom: 32px;
      }
      &__title {
        padding-bottom: 32px;
      }
      &__img-1 {
        display: none;
      }
      &__img-2 {
        display: none;
      }
      &__img-3 {
        display: none;
      }
      &__accordion {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}
