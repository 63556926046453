.block-header {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid rgba(18, 18, 18, 0.05);
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  border-radius: 18px;
  padding: 36px;
  margin: 32px 0;

  &__block-text {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      padding-right: 12px;
    }
    > div {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #020202;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      span {
        font-size: 12px;
        line-height: 140%;
        color: rgba(2, 2, 2, 0.75);
        // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      }
    }
  }
  &__block-button {
    margin-left: auto;
  }
}

@media (max-width: 992px) {
  .block-header {
    flex-direction: column;
    &__block-button {
      margin-left: auto;
      margin-right: auto;
      margin-top: 22px;
    }
  }
}
