.about {
  border-top: 1px solid #eef1f3;
  background: white;
  padding-top: 32px;
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 124.18%;
    display: flex;
    align-items: center;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    margin-bottom: 32px;
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(2, 2, 2, 0.75);
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  }
}

@media (max-width: 992px) {
  .about {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-left: 16px;
  }
}
