.subscription {
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  background-repeat: no-repeat;
  background-size: auto;
  background-position-y: bottom;
  &__left {
    position: absolute;
    left: 150px;
    top: calc(50% - 175px);
    img {
      width: 250px;
    }
  }
  &__right {
    position: absolute;
    right: 150px;
    top: calc(50% - 190px);
    img {
      width: 280px;
    }
  }
  &__row {
    margin-bottom: 140px;
    max-width: 992px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__images {
      margin-top: 44px;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      margin-bottom: 20px;
      img {
        max-width: 400px;
      }
    }
    &__text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      color: #ffffff;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      max-width: 400px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
    &__button {
      margin-bottom: 24px;
      button {
        width: 400px;
        text-align: center;
        justify-content: center;
      }
    }
    &__helpers {
      display: flex;
      flex-direction: row;
      div {
        display: flex;
        align-items: center;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        color: #ffffff;
        // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

@media (max-width: 992px) {
  .subscription {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
    &__left {
      display: none;
    }
    &__right {
      display: none;
    }
    &__row {
      width: calc(100% - 10px);
      &__images {
        // border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        // margin-bottom: 20px;
        img {
          width: 100%;
          // max-width: 40wi0px;
        }
      }
      &__button {
        button {
          width: 200px;
        }
      }
      &__helpers {
        > div {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
}
