.articles {
  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 124.18%;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    padding-top: 24px;
  }
  &__text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;
    color: #242424;
    margin-top: 14px;
  }
  h4 {
    font-size: 18px;
    line-height: 25px;
    margin-top: 0;
    margin-bottom: 0px;
  }
  h5 {
    font-weight: 600;
    line-height: 160%;
    margin: 0;
  }
  br {
    // margin-bottom: 15px;
    font-size: 15px;
    display: block;
    content: ' ';
  }
  &__block-img {
    img {
      width: 100%;
    }
    p {
      margin-bottom: 24px;
    }
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    div {
      flex: 1;
    }
    > div:last-child {
      padding-left: 15px;
    }
  }
}

@media (max-width: 992px) {
  .articles {
    width: calc(100% - 32px);
    margin-left: 15px;
    margin-right: 15px;
    &__block-img {
      flex-direction: column !important;
      img {
        width: 100%;
      }
    }
  }
}
