.register-container {
  min-height: 70vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-position: center;
  width: 100%;
  .calculate__block-calc {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
}

.register {
  background: #ffffff;
  border: 1px solid rgba(18, 18, 18, 0.05);
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.25);
  border-radius: 18px;
  padding: 32px;
  margin-top: 32px;
  margin-bottom: 64px;
  min-height: 400px;
  width: 500px;
  &__stepper {
    padding-bottom: 30px;
  }
}

.personal-data {
  &__block-input {
    display: flex;
    flex-direction: column;
  }
  &__block-title {
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 124.18%;
      color: #020202;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      padding-bottom: 12px;
    }
    &__desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #020202;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      padding-bottom: 32px;
    }
  }
  &__label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: rgba(2, 2, 2, 0.75);
    position: relative;
    top: -3px;
  }
  &__button-block {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    button:first-child {
      margin-right: 5px;
    }
    button:last-child {
      margin-left: 5px;
    }
    button {
      flex: 1;
      justify-content: center;
    }
  }
}
.MuiInputBase-root {
  border: 1px solid rgba(18, 18, 18, 0.05) !important;
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1) !important;
  border-radius: 70px !important;
  margin-bottom: 24px;
  height: 50px;
}
.Mui-focused {
  color: rgba(18, 18, 18, 0.8) !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: rgba(18, 18, 18, 0.05) !important;
  border-width: 1px !important;
}
.MuiInputBase-input {
  padding-bottom: 3.5px !important;
}
.MuiAutocomplete-input .MuiOutlinedInput-root {
  padding-bottom: 3.5px !important;
}
.react-datepicker {
  position: relative;
  &__input-container {
    position: relative;
    input {
      width: calc(100% - 16px);
      height: 45px;
      border: 1px solid var(--color-button-background-dark);
      box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1) !important;
      border-radius: 70px !important;
      padding-left: 16px;
      position: relative;
    }
    input:focus {
      border-color: var(--color-button-background-dark) !important;
    }
    .react-datepicker-ignore-onclickoutside {
      border-color: var(--color-button-background-dark) !important;
    }
  }
}
.MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-top: 3px !important;
}
.custom-block {
  .MuiInputBase-input {
    padding-bottom: 17.5px !important;
  }
}

.custom-radio {
  background: #ffffff;
  border: 1px solid rgba(18, 18, 18, 0.05);
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  border-radius: 70px;
  height: 50px;
}
.custom-block-radio {
  display: flex;
  flex-direction: row !important;
  label {
    flex: 1;
  }
  label:first-child {
    margin: 0 !important;
    margin-right: 5px !important;
  }
  label:last-child {
    margin: 0 !important;
    margin-left: 5px !important;
  }
}
.pasport__block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
.MuiFormHelperText-root {
  position: absolute;
  bottom: 0;
}
.errors-helper {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  color: #d32f2f;
  margin-right: 14px px;
  margin-bottom: 0;
  margin-left: 14px;
}
.custom-date {
  .MuiInputBase-input {
    padding-top: 5px !important;
  }
}

@media (max-width: 992px) {
  .register {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.face-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__images {
    position: absolute;
    margin-top: 70px;
    animation: moving 3s infinite linear;
  }

  &__title {
    margin-top: 100px;
    color: #242424;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 16px;
  }
  &__elem-list {
    color: rgba(36, 36, 36, 0.75);
    font-family: 'Gilroy';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
    position: relative;
    display: flex;
    align-items: center;
  }
  &__elem-list-active {
    color: rgba(36, 36, 36, 0.75);
    font-family: 'Gilroy';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
    position: relative;
    display: flex;
    align-items: center;
  }
  &__elem-list-active:last-child {
    margin-bottom: 0;
  }
  &__elem-list:last-child {
    margin-bottom: 0;
  }
  &__elem-list::before {
    content: ' ';
    position: absolute;
    width: 16px;
    height: 17px;
    left: -20px;
    background-image: url('./small-circle.png');
    background-repeat: no-repeat;
    animation: moving 3s infinite linear;
  }

  &__elem-list-active::before {
    content: ' ';
    position: absolute;
    width: 18px;
    height: 18px;
    left: -22px;
    background-image: url('./small-ok.png');
    background-repeat: no-repeat;
    background-size: 18px;
  }
}
@keyframes moving {
  100% {
    transform: rotate(-360deg);
  }
}

@media (max-width: 992px) {
  .face-loader {
    width: 100%;
    position: relative;
    // &__images {
    //   width: 100%;
    //   // display: none;
    // }
    &__title {
      // font-size: 18px;
    }
    &__elem-list {
    }
  }
}
