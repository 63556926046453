.register-container {
  &__block {
    width: 300px;
    background: #ffffff;
    border: 1px solid rgba(18, 18, 18, 0.05);
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.25);
    border-radius: 18px;
    padding: 32px;
    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 124.18%;
      color: #020202;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      padding-bottom: 32px;
    }
  }
  &__button-reload-password {
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: rgba(2, 2, 2, 0.25);
    opacity: 0.45;
  }
  &__button-register {
    width: 100%;
    justify-content: center;
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    img {
      padding-right: 5px;
    }
  }
  &__button-login {
    width: 100%;
    justify-content: center;
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    img {
      padding-right: 5px;
    }
  }
}

.reload-password {
  .register-container {
    &__block {
      width: 300px;
      background: #ffffff;
      border: 1px solid rgba(18, 18, 18, 0.05);
      box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.25);
      border-radius: 18px;
      padding: 32px;
      &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 124.18%;
        color: #020202;
        // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
        padding-bottom: 12px;
      }
      &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #020202;
        // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
        padding-bottom: 32px;
      }
    }
  }
}
@media (max-width: 992px) {
  .reload-password {
    .register-container {
      &__block {
        width: auto;
        max-width: 300px;
        padding: 16px;
      }
    }
  }
}
