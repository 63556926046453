.dashboard {
  display: flex;
  flex-direction: column;

  &__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 20px;
    row-gap: 20px;
    column-gap: 20px;
    position: relative;
    &__block {
      background: rgba(247, 248, 253, 0.01);
      border: 1px solid #e8e8ea;
      border-radius: 18px;
      padding: 14px 17px;

      position: relative;
    }
    &__label {
      color: #8898aa;
    }
    &__value {
      font-weight: 600;
      color: #32325d;
      font-size: 1.25rem;
    }
    &__icon {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: var(--color-button-green);
      padding: 10px;
      border-radius: 90px;
      padding-bottom: 5px;
    }
  }
}
